import React from 'react';
import './App.css';

//Primera pantalla
function Home(props) {
var keys = Object.keys(props.langs).filter(key => key !== props.language);

return (
  <React.Fragment>
   <div className="header">
        <img src="logo.png" data-qa="brand-logo" className="logo" alt=""/>
    </div>
    <div className="principal">
      <div className="welcome_content">
              <div className="title">{props.home.title[props.language]}</div>
              <div className="language-selector">
                {keys.map((lang, index) => (
                  <em className="language-div" key={lang+index}> 
                    <button
                    key={lang}
                    className="language-button"
                    onClick={() => props.handleLanguageChange(lang)}
                  >
                    {props.langs[lang]}
                  </button>
                  <span  hidden= {index === keys.length-1}> / </span>
                  </em>
                
                ))}
              </div>
              <div className="description" dangerouslySetInnerHTML={{__html: props.home.description[props.language].join('')}}></div>
      </div>
    </div>
    <div className="footer">
      <div className="front_footer">
            <div className="seconds">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" data-qa="animated-clock" className="clock"><circle cx="6" cy="6" fill="#333333" r="6"></circle><line x1="6" y1="6" x2="6" y2="3" stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round"></line><line x1="6" y1="6" x2="6" y2="3" stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" id="clock-hour-hand"></line></svg>
              <span className='seconds-text'>{props.home.seconds[props.language]}</span>
            </div>
            <div>
                <button
                    key="comencar"
                  className="button_start"
                    onClick={() => props.goToTest()}
                  >
                  {props.home.startButton[props.language]}
                  </button>
                  <span className="return" dangerouslySetInnerHTML={{__html: props.home.return[props.language]}}></span>
            </div>
        </div>
          
    </div>
  </React.Fragment>
);

}

export default Home;

