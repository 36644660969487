import React from 'react';
import './App.css';

function Question(props) {
  const letters = ['A','B','C','D','D','F','G','H','I','J','K','L', 'M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

return (
    <React.Fragment>
       
    <div className="main-question">
        <div className="question-title">
          <span className='question-id'>{props.question.id}</span>
          <span className='arrow'><svg height="10" width="11"><path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path><path d="M8 4v2H0V4z"></path></svg></span>
          <span dangerouslySetInnerHTML={{ __html: props.question.text[props.language] }}></span>
        </div>
        <div className="question-description">{props.question.description[props.language]}</div>
          {props.question.options.map((option, index) => (
            <button id={'option_' + props.question.id + '_' + index} key={'option_' + props.question.id + '_' + index} ref = {ref => props.divRef.current[index] = ref} className="question-button" onClick={(event) => props.handleOptionClick(option, event)}>
              <span className="letter">{letters[index]}</span>{option.text[props.language]}
            </button>
          ))}
          <div style={{marginLeft: 35}}>
              <button className="button_start" onClick={() => props.nextQuestionOrResult()} hidden={props.hiddenButton}>
                  {props.textButton}
              </button>
          </div>
          
      </div>
      <div className="images">
        <img src={props.question.image} alt="Current option" />
      </div>
      <div className="footer_q">
         
      </div>
  </React.Fragment>
);

}

export default Question;

