import React, {useRef, useState, useEffect } from 'react';
import './App.css';
import Question from './Question';
import Home from './Home';

function App() {
  const [data, setData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [result, setResult] = useState(null);
  const [language, setLanguage] = useState('ca');
//navigator.language.slice(0, 2)

  const [isLoading, setIsLoading] = useState(true);
  const [main, setMain] = useState(true);
  const [hiddenButton, setHiddenButton] = useState(true);

  const [selected, setSelected] = useState(null);
  const [textButton, setTextButton] = useState('');

  const divRef = useRef([]);

// handle language change
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  //seleccion de opción

  const handleOptionClick = (option, e) => {
      if(e.target.classList.contains("intermitente")){
        e.target.classList.remove("intermitente");
        setSelected(null);
      }else{
        divRef.current.forEach(element => {
          if(element)element.classList.remove("intermitente")
        });
        e.target.classList.add("intermitente");
        setSelected(option);
    }
  }


  //Ir a la proxima pregunta o al resultado
  const nextQuestionOrResult = () =>{
      divRef.current = [];
      if ('nextQuestion' in selected) {
        const nextQuestion = data.questions.find(q => q.id === selected.nextQuestion);
        setCurrentQuestion(nextQuestion);
        setSelected(null);
      } else if ('result' in selected) {
        setSelected(null);
        const result = data.results.find(r => r.id === selected.result);
        setResult(result);
        setCurrentQuestion(null);
      }
    }
    //Cargamos el json
    useEffect(() => {
      fetch('flow.json')
        .then(response => response.json())
        .then(data => {
          setData(data);
          setCurrentQuestion(data.questions[0]);
          setIsLoading(false);  // Set isLoading to false when the data has been loaded
        });
    }, []);

    //Cambio de la opción seleccionada
    useEffect(() => {
        if(selected!=null){
          if ('result' in selected) {
            setTextButton(data.home.sendButton[language]);
          }else{
            setTextButton(data.home.okButton[language]);
          }
          setHiddenButton(false);
        }else{
          setHiddenButton(true);
        }
    }, [selected]);

// In your component rendering logic, check if the data is still loading
if (isLoading) {
  return <div>Loading...</div>;  // You can replace this with a loading spinner or any other loading indicator
}

const goToTest = () => {
  setMain(false);
};

  if(main){
    return (
      <div className="welcome">
          <Home home={data.home} langs={data.languages} language={language} goToTest={goToTest} handleLanguageChange={handleLanguageChange}></Home>
      </div>
    );
  }else if(result){
    return (
    <div className="welcome">
       <div className="header">
            <img src="logo.png" data-qa="brand-logo" className="logo" alt=""/>
        </div>
      <React.Fragment>
          <div className="principal">
              <div className="welcome_content">
              <div className="description" dangerouslySetInnerHTML={{__html: result.text[language]}}></div>
	      <br />
              <a href={result.link}><button className="button_start" dangerouslySetInnerHTML={{__html: result.button[language]}}></button></a>
              </div>
            </div>
      </React.Fragment>
    </div>

  );
    }else{
      return (
        <div className="page">
          <React.Fragment>
            <div className="header">
                <img src="logo.png" data-qa="brand-logo" className="logo"  alt=""/>
            </div>
            <Question question={currentQuestion} 
                  handleOptionClick={handleOptionClick}
                  language={language}
                  divRef={divRef}
                  okButton={data.home.okButton}
                  hiddenButton={hiddenButton}
                  nextQuestionOrResult={nextQuestionOrResult}
                  textButton = {textButton}
                  ></Question>
          </React.Fragment>
        </div>
    
      );

      }

}

export default App;

